import { useEffect } from "react";
import Image from "next/image";
import XMarkIcon from "@/components/Icon/XMarkIcon";

export default function Snackbar({
  type,
  message,
  setIsShow,
  snackbarRef,
}: any) {
  useEffect(() => {
    setIsShow(true);
    const timer = setTimeout(() => {
      setIsShow(false);
    }, 4000);

    return () => {
      clearTimeout(timer);
    };
  }, [type, message]);

  return (
    <div
      className="fixed z-[9999999] bottom-0 left-[10px] flex justify-between items-start mb-4 px-4 py-4 rounded-md shadow-lg bg-black bg-opacity-80 cursor-pointer space-x-4"
      onClick={() => setIsShow(false)}
      ref={snackbarRef}
    >
      <div className="max-w-[300px] flex space-x-3 items-start justify-center z-80">
        {type == "success" ? (
          <Image
            width={20}
            height={20}
            src="/assets/tick.svg"
            alt="tick"
            className="w-[20px] h-[20px]"
          />
        ) : (
          <Image
            width={20}
            height={20}
            src="/assets/cross.svg"
            alt="cross"
            className="w-[20px] h-[20px]"
          />
        )}
        <span className="text-sm font-bold text-white text-[0.9rem] pt-2">
          {message}
        </span>
      </div>
      <XMarkIcon className="w-[23px] h-[23px] text-white z-80" />
    </div>
  );
}
