import { useRef, PropsWithChildren } from "react";
import Head from "next/head";
import Loading from "@/components/Loading";
import LoginModal from "@/components/modals/LoginModal";
import Snackbar from "@/components/alert/Snackbar";
import { useAuthValue } from "@/contexts/contextAuth";
import {
  hasRunningRequestsSelector,
  useApiRequestTracker,
} from "@/stores/api-request-tracker.store";

interface Props extends PropsWithChildren {}

const Layout = ({ children }: Props) => {
  const snackbarRef = useRef<HTMLDivElement>(null);
  const {
    isShow,
    type,
    message,
    source,
    setIsShow,
    isSignin,
    isSignup,
    setSource,
  } = useAuthValue();
  const isLoading = useApiRequestTracker(hasRunningRequestsSelector);

  return (
    <div>
      <Head>
        <link rel="icon" href="/favicon.ico" key="favicon" />
      </Head>

      <main className="relative bg-white">{children}</main>
      {isShow && (
        <Snackbar
          type={type}
          message={message}
          setIsShow={setIsShow}
          snackbarRef={snackbarRef}
        />
      )}
      <LoginModal />
      {isLoading && <Loading />}
    </div>
  );
};

export default Layout;
