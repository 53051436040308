import { ReactElement, ReactNode, useCallback, useState } from "react";
import { ForgotPassword } from "./ForgotPassword";
import { ResetPassword } from "./ResetPassword";
import { Signin } from "./Signin";
import { Signup } from "./Signup";
import { AuthenticationType } from "./types";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import clsx from "classnames";

interface Props {
  initialScreen: AuthenticationType;
}
export const Authentication = ({ initialScreen }: Props) => {
  const [screen, setCurrentScreen] =
    useState<AuthenticationType>(initialScreen);
  const isDesktop = useMediaQuery("(min-width: 768px)");
  let child: ReactElement | null = null;
  if (screen === AuthenticationType.Signin) {
    child = <Signin changeScreen={setCurrentScreen} />;
  }
  if (screen === AuthenticationType.Signup) {
    child = <Signup changeScreen={setCurrentScreen} />;
  }
  if (screen === AuthenticationType.ForgotPassword) {
    child = <ForgotPassword changeScreen={setCurrentScreen} />;
  }
  if (screen === AuthenticationType.ResetPassword) {
    child = <ResetPassword changeScreen={setCurrentScreen} />;
  }

  if (child) {
    if (isDesktop) {
      return child;
    }
    return <div className={"min-h-[60vh]"}>{child}</div>;
  }

  return null;
};
