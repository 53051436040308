export enum AuthenticationType {
  Signin = "signin",
  Signup = "signup",
  ForgotPassword = "forgot-password",
  ResetPassword = "reset-password",
}

export interface AuthenticationScreenProps {
  changeScreen: (screen: AuthenticationType) => void;
}
