import { create } from "zustand";

type ApiRequestTrackerStore = {
  runningRequests: number;
  increment: () => void;
  decrement: () => void;
};
export const hasRunningRequestsSelector = (state: ApiRequestTrackerStore) =>
  state.runningRequests > 0;
export const useApiRequestTracker = create<ApiRequestTrackerStore>((set) => ({
  runningRequests: 0,
  increment: () =>
    set((state) => ({ runningRequests: state.runningRequests + 1 })),
  decrement: () =>
    set((state) => ({ runningRequests: state.runningRequests - 1 })),
}));
