import { createHmac } from "crypto";
import axios, { AxiosRequestConfig } from "axios";
import { hashSecret } from "./clientConfig";
import { LIMIT } from "./constants";
import qs from "qs";
import { MoreFilter } from "@/interfaces/MoreFilter";
import { LoanRate } from "@/interfaces/Loans";
import { useApiRequestTracker } from "@/stores/api-request-tracker.store";

/*************************
 * This can be called from client or server. Can't assume the endpoint
 */

const isBrowser = typeof window !== "undefined";
const api = (url: string) => {
  const apiClient = axios.create({
    baseURL: isBrowser ? url : process.env.PA_LISTING_API,
  });
  apiClient.interceptors.request.use(
    (req: any) => {
      if (isBrowser) {
        useApiRequestTracker.getState().increment();
      }
      const secret = hashSecret;
      const payload = {
        body: req.data || {},
        userAgent: isBrowser ? window.navigator.userAgent : "build-server",
        url: req.url,
      };
      const hash = createHmac("sha256", secret)
        .update(JSON.stringify(payload))
        .digest("base64");
      req.headers["x-pa"] = hash;
      req.headers["x-client"] = payload.userAgent;
      return req;
    },
    (err) => {
      if (isBrowser) {
        useApiRequestTracker.getState().decrement();
      }
      return Promise.reject(err);
    }
  );

  apiClient.interceptors.response.use(
    (response) => {
      if (isBrowser) {
        useApiRequestTracker.getState().decrement();
      }
      return response.data;
    },
    (err) => {
      if (isBrowser) {
        useApiRequestTracker.getState().decrement();
      }
      console.log("err: ", err);
      return Promise.reject(err);
    }
  );

  const makeHash = (url: string, agent: string, body: any) => {
    const payload = {
      body: body || {},
      userAgent: agent,
      url,
    };

    const hash = createHmac("sha256", hashSecret)
      .update(JSON.stringify(payload))
      .digest("base64");
    return hash;
  };

  const getAPIData = async (url: string) => {
    try {
      const response = await apiClient.get(url);
      return response;
    } catch (e: any) {
      console.warn(`failed to fetch [${url}] - ${e.message}`);
      return { data: [] };
    }
  };

  const getBlogData = async (location: string) => {
    const url = `/api/public/blogs/suggestions/${location}`;
    return apiClient.get(url);
  };

  const text_format = (text: string) => {
    const formatted_text = text
      .replace(/ /g, "%20")
      .replace(/\[/g, "%5B")
      .replace(/\]/g, "%5D")
      .replace(/\//g, "%2B")
      .replace(/\$/g, "%24");
    return formatted_text;
  };

  const getTotalData = async (limit = LIMIT): Promise<any> => {
    console.log("getTotalData: ", limit);
    const filter = {
      _page: 0,
      _limit: limit,
    };
    const listings: any[] = [];
    //let pg: any = await getAPIData(`/api/listings?${qs.stringify(filter)}`);
    //listings.push(...pg.data);
    //const pages = Math.ceil((pg?._meta?.total ?? 0) / limit) - 1;
    //const cur = pg?._meta?.page ?? 0;
    let cnt = limit;
    while (cnt >= limit) {
      const pg: any = await getAPIData(`/api/listings?${qs.stringify(filter)}`);
      listings.push(...pg.data);
      //pages = Math.ceil((pg?._meta?.total ?? 0) / limit) - 1;
      cnt = pg.data.length;
      filter._page++;
    }
    return {
      _meta: {
        page: 0,
        total: listings.length,
        limit: Math.max(limit, listings.length),
      },
      data: listings,
    };
  };

  const getSiteMapData = async (): Promise<any> => {
    const limit = LIMIT;
    const filter = {
      _page: 0,
      _limit: LIMIT,
      internal: true,
    };
    const listings: any[] = [];
    let cnt = limit;
    while (cnt >= limit) {
      const pg: any = await getAPIData(`/api/listings?${qs.stringify(filter)}`);
      listings.push(...pg.data);
      //pages = Math.ceil((pg?._meta?.total ?? 0) / limit) - 1;
      cnt = pg.data.length;
      filter._page++;
    }
    return {
      _meta: {
        page: 0,
        total: listings.length,
        limit: Math.max(limit, listings.length),
      },
      data: listings,
    };
  };

  const getDataBySlug = async (slug: string): Promise<any> => {
    const url = `/api/listings/slug/${slug}`;
    return getAPIData(url);
  };

  const getValueReportBySlug = async (slug: string): Promise<any> => {
    const url = `/api/listings/retail-price/${slug}`;
    return await apiClient.get(url);
  };

  const validateRetPasswordToken = async (token: string) => {
    const url = `/api/users/validate-reset-password?token=${token}`;
    return apiClient.get(url);
  };

  const getDealNowDealBySlug = async (slug: string): Promise<any> => {
    const url = `/api/deal-now/${slug}`;
    return getAPIData(url);
  };

  const getPageData = async (
    rows: number,
    limit: number,
    current: number,
    vehicleType: string,
    searchKey: string,
    make: string,
    models: Array<string>,
    bodyType: Array<string>,
    minPrice: number,
    maxPrice: number,
    minYear: number,
    maxYear: number,
    minMiles: number,
    maxMiles: number,
    isVerified: string,
    moreFiltersArr: MoreFilter,
    location: string,
    state: string,
    lat: number,
    lng: number,
    radius: number,
    sort: string,
    excludeSlugs?: Array<string>,
    Trim?: Array<string>
  ): Promise<any> => {
    const getLocationFilter = () => {
      if (state != "") {
        return {
          state,
        };
      } else if (location != "") {
        return {
          radius,
          latlng: {
            latitude: lat,
            longitude: lng,
          },
        };
      }
      return {};
    };
    const getSortPayload = () => {
      if (sort == "Newest") {
        return {
          _sort: [
            {
              column: "payment.date",
              direction: "desc",
            },
          ],
        };
      } else if (sort == "Highest price") {
        return {
          _sort: [
            {
              column: "Price",
              direction: "desc",
            },
          ],
        };
      } else if (sort == "Lowest price") {
        return {
          _sort: [
            {
              column: "Price",
              direction: "asc",
            },
          ],
        };
      } else if (sort == "Lowest mileage") {
        return {
          _sort: [
            {
              column: "Mileage",
              direction: "asc",
            },
          ],
        };
      } else {
        return {
          _sort: [
            {
              column: "RegistrationYear",
              direction: "desc",
            },
          ],
        };
      }
    };
    const trimArray = (e: string) => e.trim();
    const makes = make
      ?.split(/,\s*|\s+/)
      .filter((s) => s && s.trim().length > 0);
    const filter = {
      _page: current,
      _limit: limit,
      ...(vehicleType !== "All Vehicles" && {
        vehicleType,
      }),
      ...(searchKey && {
        _search: searchKey,
      }),
      ...getSortPayload(),
      RegistrationYear: {
        ...(minYear && {
          $gte: minYear,
        }),
        ...(maxYear && {
          $lte: maxYear,
        }),
      },
      Mileage: {
        ...(minMiles && {
          $gte: minMiles,
        }),
        ...(maxMiles && {
          $lte: maxMiles,
        }),
      },
      ...(excludeSlugs && { excludedSlugs: excludeSlugs }),
      CarMake: makes,
      CarModel: models,
      ...(Trim && { Trim }),
      BodyStyle: bodyType,
      ExteriorColor: moreFiltersArr.exteriorColor.map(trimArray),
      InteriorColor: moreFiltersArr.interiorColor.map(trimArray),
      Fuel: moreFiltersArr.fuelType.map(trimArray),
      Transmission: moreFiltersArr.transmission.map(trimArray),
      DriveType: moreFiltersArr.driveType.map(trimArray),
      Cylinders: moreFiltersArr.cylinders.map(trimArray),
      Price: {
        ...(minPrice && {
          $gte: minPrice,
        }),
        ...(maxPrice && {
          $lte: maxPrice,
        }),
      },
      ...(isVerified && {
        Verified: isVerified,
      }),
      ...getLocationFilter(),
    };

    return getAPIData(`/api/listings?${qs.stringify(filter)}`);
  };

  const getMarketInsightListings = async (query: any): Promise<any> => {
    const {
      CarMake,
      CarModel,
      RegistrationYear,
      listingLocation: {
        geometry: { latitude, longitude },
      },
      Trim,
      Radius,
    } = query;
    const filters = {
      CarMake,
      CarModel,
      RegistrationYear,
      lat: latitude,
      lng: longitude,
      ...(Trim === "All Trims" ? {} : { Trim }),
      Radius,
    };

    return getAPIData(`/api/listings/market-insights?${qs.stringify(filters)}`);
  };

  const getDataFromURL = async (url: string): Promise<any> => {
    return await getAPIData(url);
  };

  const getInitMakeData = async (): Promise<any> => {
    const url = "/api/listings/automobile-makes";
    return getAPIData(url);
  };

  const getModelDataByMake = async (make: string): Promise<any> => {
    let formatted = "";
    if (make.includes(" ")) {
      const arrByMake = make.split(" ");
      const len = arrByMake.length;
      arrByMake.map((item: string, index: number) => {
        if (len == index + 1) {
          formatted += item;
        } else {
          formatted += item + "%20";
        }
      });
    } else {
      formatted = make;
    }
    const url = `/api/vehicles/make/${formatted}`;
    return getAPIData(url);
  };

  const getCarDetailsFilter = async (): Promise<any> => {
    const url = `/api/listings/car-details-filter`;
    return getAPIData(url);
  };

  const submitContact = async (contact: any): Promise<any> => {
    const url = `/api/contacts`;
    return apiClient.post(url, contact);
  };

  const submitLicensePlate = async (
    url: string,
    state: string
  ): Promise<any> => {
    return apiClient.post(url, { state });
  };

  const getLoanRates = async (
    type = "vehicle"
  ): Promise<{ data: LoanRate[] }> => {
    return getAPIData(`/api/usa/loan/rates?${qs.stringify({ type })}`);
  };

  const makeRequest = async <T = any>(
    config: AxiosRequestConfig
  ): Promise<T> => {
    return apiClient.request<T>(config) as Promise<T>;
  };

  const signIn = (payload: {
    accessToken: string;
    idToken: string;
    refreshToken: string;
  }) => {
    return makeRequest({
      url: "/api/users/signin",
      method: "POST",
      data: payload,
    });
  };

  const resetPassword = (payload: {
    email: string;
    password: string;
    token: string;
  }) => {
    return makeRequest({
      data: payload,
      method: "POST",
      url: "/api/users/reset-password",
    });
  };
  const forgotPassword = (payload: { email: string }) => {
    return makeRequest({
      data: payload,
      method: "POST",
      url: "/api/users/forgot-password",
    });
  };

  return {
    makeHash,
    text_format,
    getTotalData,
    getDataBySlug,
    getPageData,
    getDataFromURL,
    getMarketInsightListings,
    getInitMakeData,
    getModelDataByMake,
    getCarDetailsFilter,
    getBlogData,
    submitContact,
    submitLicensePlate,
    getDealNowDealBySlug,
    getLoanRates,
    validateRetPasswordToken,
    getValueReportBySlug,
    makeRequest,
    getSiteMapData,
    signIn,
    resetPassword,
    forgotPassword,
  };
};

export default api;
