import { useEffect, useState } from "react";
import { MoreFilter } from "@/interfaces/MoreFilter";
import { initFilters } from "@/libs/constants";
import {
  MIN_PRICE,
  MAX_PRICE,
  MIN_YEAR,
  MAX_YEAR,
  MIN_MILES,
  MAX_MILES,
} from "@/libs/constants";

const useSearch = () => {
  const [searchKey, setSearchKey] = useState<string>("");
  const [vehicleType, setVehicleType] = useState<string>("");
  const [make, setMake] = useState<string>("");
  const [models, setModels] = useState<Array<string>>([]);
  const [bodyType, setBodyType] = useState<Array<string>>([]);
  const [minPrice, setMinPrice] = useState<number>(MIN_PRICE);
  const [maxPrice, setMaxPrice] = useState<number>(MAX_PRICE);
  const [minYear, setMinYear] = useState<number>(MIN_YEAR);
  const [maxYear, setMaxYear] = useState<number>(MAX_YEAR);
  const [minMiles, setMinMiles] = useState<number>(MIN_MILES);
  const [maxMiles, setMaxMiles] = useState<number>(MAX_MILES);
  const [isVerified, setIsVerified] = useState<string>("");
  const [moreFiltersArr, setMoreFiltersArr] = useState<MoreFilter>(initFilters);
  const [location, setLocation] = useState<string>("");
  const [state, setState] = useState<string>("");
  const [zipCode, setZipCode] = useState<string>("");
  const [lat, setLat] = useState<number>(0);
  const [lng, setLng] = useState<number>(0);
  const [radius, setRadius] = useState<number>(50);
  const [sort, setSort] = useState<string>("Newest");
  const [current, setCurrent] = useState<number>(0);

  const [selectedRadius, setSelectedRadius] = useState<number>(50);
  const [selectedPlace, setSelectedPlace] = useState<string>("");
  const [selectedState, setSelectedState] = useState<string>("");
  const [selectedLat, setSelectedLat] = useState<number>(0);
  const [selectedLng, setSelectedLng] = useState<number>(0);
  const [isOnlyState, setIsOnlyState] = useState<boolean>(false); //when searching only state in LocationModal
  const [triggerReset, setTriggerReset] = useState(false);

  const clearLocation = () => {
    setSelectedRadius(50);
    setSelectedPlace("");
    setSelectedState("");
    setSelectedLat(0);
    setSelectedLng(0);
    setIsOnlyState(false);
    setLocation("");
    setLat(0);
    setLng(0);
    setZipCode("");
    setRadius(50);
    setState("");
    document.body.style.overflowY = "scroll";
  };

  const clearMakeModel = () => {
    setMake("");
    setModels([]);
    document.body.style.overflowY = "scroll";
  };

  useEffect(() => {
    if (triggerReset) {
      setMinPrice(MIN_PRICE);
      setMaxPrice(MAX_PRICE);
      setMinYear(MIN_YEAR);
      setMaxYear(MAX_YEAR);
      setMinMiles(MIN_MILES);
      setMaxMiles(MAX_MILES);
      setBodyType([]);
      setIsVerified("");
      setMoreFiltersArr(initFilters);
      setTriggerReset(false);
    }
  }, [triggerReset]);

  const clearFilters = () => {
    setTriggerReset(true);
    document.body.style.overflowY = "scroll";
  };

  return {
    vehicleType,
    setVehicleType,
    searchKey,
    setSearchKey,
    make,
    setMake,
    models,
    setModels,
    bodyType,
    setBodyType,
    minPrice,
    setMinPrice,
    maxPrice,
    setMaxPrice,
    minYear,
    setMinYear,
    maxYear,
    setMaxYear,
    minMiles,
    setMinMiles,
    maxMiles,
    setMaxMiles,
    isVerified,
    setIsVerified,
    moreFiltersArr,
    setMoreFiltersArr,
    state,
    setState,
    location,
    setLocation,
    zipCode,
    setZipCode,
    lat,
    setLat,
    lng,
    setLng,
    radius,
    setRadius,
    sort,
    setSort,
    selectedRadius,
    setSelectedRadius,
    selectedPlace,
    setSelectedPlace,
    selectedState,
    setSelectedState,
    selectedLat,
    setSelectedLat,
    selectedLng,
    setSelectedLng,
    isOnlyState,
    setIsOnlyState,
    current,
    setCurrent,
    clearLocation,
    clearMakeModel,
    clearFilters,
    triggerReset,
    setTriggerReset,
  };
};

export default useSearch;
