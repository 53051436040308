import api from "@/libs/api";

const shouldPrefix =
  typeof window === "object"
    ? Boolean(process.env.NEXT_PUBLIC_PREFIX_PRELOGIN === "true")
    : false;
/**
 *  This should be used for all CLIENT based prelogin api access
 */

/*
async function getApiPath(): Promise<string> {
  try {
    if (typeof window !== undefined) {
      const resp = await fetch("/assets/cfg.json");
      const cfg = await resp.json();
      return cfg.paServerPreloginApi;
    }
    if (!paServerDomain)
      throw new Error("window and paServerDomain both undefined");
    return paServerDomain;
  } catch (e: any) {
    console.log(`Fetch config failed: ${e.message}`);
    return "/";
  }
}

const useApi = () => api(getApiPath());
*/
const useApi = () => api(shouldPrefix ? "/prelogin" : "/");

export default useApi;
