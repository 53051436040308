import { useState } from "react";
import { useForgotPasswordForm } from "@/hooks/useForgotPasswordForm";
import Image from "next/image";
import { AuthenticationScreenProps, AuthenticationType } from "./types";
import { useApiRequestTracker } from "@/stores/api-request-tracker.store";
import getApi from "@/hooks/useApi";
import { useAuthValue } from "@/contexts/contextAuth";

const api = getApi();

export const ForgotPassword = ({ changeScreen }: AuthenticationScreenProps) => {
  const apiRequestTrackerStore = useApiRequestTracker();
  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitting: isResetting },
    watch,
  } = useForgotPasswordForm();
  const email = watch("email");
  const [isSent, setIsSent] = useState(false);
  const { setMessage, setType, setIsShow } = useAuthValue();
  const resetPassword = async () => {
    apiRequestTrackerStore.increment();
    try {
      await api.forgotPassword({
        email,
      });
      setType("success");
      setMessage(
        "Recovery Code is sent. Please check your email for the verification code."
      );
      setIsShow(true);
      setIsSent(true);
    } catch (err: any) {
      setType("error");
      setMessage(err.message);
      setIsShow(true);
    }
    apiRequestTrackerStore.decrement();
  };
  if (isSent) {
    return (
      <div>
        <div className="flex flex-col space-y-4 mb-5">
          <div className="text-[1.75rem] text-[#212529] font-bold">
            {`Password reset link sent`}
          </div>
          <div className="text-base text-[#212529] font-medium flex flex-col gap-4">
            <p>{`We've sent a reset password link to:`}</p>
            <span className="font-semibold">{email}</span>
            <p>
              {`You will receive an email if we find an existing account with the following email address. If you don’t see the email in a couple of minutes, check spam folder or resend link.`}
            </p>
          </div>
        </div>
        <div className="flex flex-col gap-3">
          <button
            onClick={() => changeScreen(AuthenticationType.Signin)}
            className={`w-full bg-[#F7F9FC] text-[#063829] text-sm py-2 px-4 rounded cursor-pointer`}
          >
            Return to login
          </button>
          <button
            disabled={Boolean(isResetting)}
            onClick={resetPassword}
            className={`w-full bg-[#F7F9FC] text-[#063829] text-sm py-2 px-4 rounded cursor-pointer`}
          >
            Resend recovery link
          </button>
        </div>
      </div>
    );
  }
  return (
    <div>
      <div
        role="link"
        onClick={() => changeScreen(AuthenticationType.Signin)}
        className="flex flex-row items-center space-x-1 mb-4 cursor-pointer"
      >
        <Image
          className=""
          width={24}
          height={24}
          src={`/assets/chevron-left.svg`}
          alt="back"
        />
        <div className="text-base">Back</div>
      </div>
      <form
        onSubmit={handleSubmit(resetPassword)}
        className="flex flex-col space-y-4"
      >
        <div className="text-[2rem] text-[#212529] font-bold">
          Forgot Password?
        </div>
        <div className="text-base text-[#212529] font-medium">
          Enter your email to receive a recovery link.
        </div>
        <div>
          <input
            type="email"
            className="border-[#9797aa] focus:border-[#00a0c7] form-control w-full block px-4 py-2 text-base font-medium bg-white bg-clip-padding border border-solid  rounded m-0"
            placeholder="Email address"
            {...register("email")}
          />
          {errors?.email && (
            <div className="text-xs text-left text-[#ed0a0a] pt-2">
              {errors.email.message}
            </div>
          )}
        </div>
        <div>
          <button
            className={`w-full bg-[#00b3de] text-white text-base font-bold py-2 px-4 border-[#117a8b] rounded ${
              isValid ? "opacity-100" : "opacity-50"
            }`}
            type="submit"
            disabled={!isValid}
          >
            {isResetting ? "Sending email..." : "Reset Password"}
          </button>
        </div>
        <hr className="text-[#c4c4c4]" />
        <div className="text-sm text-[#6c757d] px-4">
          *check your email for your password reset code
        </div>
      </form>
    </div>
  );
};
