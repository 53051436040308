import Image from "next/image";

const Loading = () => {
  return (
    <div className="fixed top-0 left-0 w-full h-screen bg-white bg-opacity-60 flex justify-center items-center overflow-x-hidden inset-0 z-[100] outline-none focus:outline-none">
      <Image
        width={640}
        height={600}
        className="w-[300px]"
        src="/assets/loading.gif"
        alt="loading"
      />
    </div>
  );
};

export default Loading;
