import { AuthProvider } from "@/contexts/contextAuth";
import { ConfigContext } from "@/contexts/contextConfig";
import { SearchProvider } from "@/contexts/contextSearch";
import { ClientConfig } from "@/libs/clientConfig";
import { PropsWithChildren } from "react";
import { PostHogProvider } from "posthog-js/react";
import posthog from "posthog-js";
interface AppProvidersInterface extends PropsWithChildren {
  clientConfig: ClientConfig;
}

export function AppProviders({
  clientConfig,
  children,
}: AppProvidersInterface) {
  return (
    <ConfigContext.Provider value={clientConfig}>
      <PostHogProvider client={posthog}>
        <SearchProvider>
          <AuthProvider>{children}</AuthProvider>
        </SearchProvider>
      </PostHogProvider>
    </ConfigContext.Provider>
  );
}
