export const LIMIT = 50;
export const LISTING_VEHICLES_LIMIT = 3;
export const PAGE_SIZE = 24;
export const BUY_PAGE_DEFAULT_LIMIT = 48;

export const vehicles = ["All Vehicles", "Auto", "ATV/OHV", "Motorcycle"];
export const vehiclesOnSellEntry = ["Auto", "ATV/OHV", "Motorcycle"];
export const comingVehicles = ["RV", "Boat"];
export const initalCarsArray = [
  {
    type: "SUV",
    img: "suv.svg",
    width: 70,
    height: 28,
    isSelected: false,
  },
  {
    type: "Sedan",
    img: "sedan.svg",
    width: 62,
    height: 21,
    isSelected: false,
  },
  {
    type: "Truck",
    img: "truck.svg",
    width: 72,
    height: 28,
    isSelected: false,
  },
  {
    type: "Hatchback",
    img: "hatchback.svg",
    width: 56,
    height: 21,
    isSelected: false,
  },
  {
    type: "Coupe",
    img: "coupe.svg",
    width: 62,
    height: 21,
    isSelected: false,
  },
  {
    type: "Convertible",
    img: "convertible.svg",
    width: 62,
    height: 21,
    isSelected: false,
  },
  {
    type: "Minivan",
    img: "minivan.svg",
    width: 62,
    height: 25,
    isSelected: false,
  },
  {
    type: "Wagon",
    img: "wagon.svg",
    width: 62,
    height: 21,
    isSelected: false,
  },
  {
    type: "Van",
    img: "van.svg",
    width: 68,
    height: 29,
    isSelected: false,
  },
];
export const sortArray = [
  "Newest inventory",
  "Highest price",
  "Lowest price",
  "Lowest mileage",
  "Newest year",
];

export const moreFilterFieldArr = [
  "Exterior color",
  "Interior color",
  "Fuel type",
  "Transmission",
  "Drive type",
  "Cylinders",
];

export const initFilters = {
  exteriorColor: Array<string>(),
  interiorColor: Array<string>(),
  fuelType: Array<string>(),
  transmission: Array<string>(),
  driveType: Array<string>(),
  cylinders: Array<string>(),
};

export type Redirect = {
  id: number;
  attributes: {
    RedirectFrom: string;
    RedirectTo: string;
    RedirectCode: string;
    Comment: string;
    createdAt: string;
    updatedAt: string;
    publishedAt: string;
  };
};

export const MIN_PRICE = 0;
export const MAX_PRICE = 1000000;
export const MIN_YEAR = 1910;
export const MAX_YEAR = new Date().getFullYear() + 1;
export const MIN_MILES = 0;
export const MAX_MILES = 300000;

export const CARD_IMAGE_SIZE_WIDTH = 456;
export const CARD_IMAGE_SIZE_HEIGHT = 242;

export const resourcesArr = [
  {
    name: "All about payments",
    link: "/payments",
  },
  {
    name: "Browse Vehicle Listings",
    link: "/buy",
  },
  {
    name: "Sell a car yourself",
    link: "/sell",
  },
  {
    name: "Learn about paperwork",
    link: "/blog/what-paperwork-do-i-need-to-sell-my-car-privately",
  },
  {
    name: "What is PrivateAuto Pay?",
    link: "/blog/what-is-privateauto-pay",
  },
  {
    name: "Get car transport",
    link: "/transport/cars",
  },
  {
    name: "What to do with license plates",
    link: "/license-plates",
  },
  {
    name: "What is DealNow?",
    link: "/dealnow",
  },
  {
    name: "Understanding car sales tax",
    link: "/sales-taxes",
  },
  {
    name: "How to transfer a car title",
    link: "/title/transfer",
  },
  {
    name: "All about the bill of sale",
    link: "/bill-of-sale",
  },
  {
    name: "Apply for car financing",
    link: "/financing/cars",
  },
];

export const fantasticArticlesArr = [
  {
    article: "How to pay for a private-party car",
    link: "/payments/the-best-way-to-pay-for-a-car",
  },
  {
    article: "What is the best site to buy a car online?",
    link: "/buy/where-is-the-best-place-to-buy-a-used-car-online",
  },
  {
    article: "How much do license plates cost?",
    link: "/license-plates/costs",
  },
  {
    article: "What is a release of liability?",
    link: "/paperwork/dmv-release-of-liability",
  },
  { article: "What is a salvage title?", link: "/title/salvage" },
  {
    article: "What is a digital license plate?",
    link: "/license-plates/what-is-a-digital-license-plate",
  },
  {
    article: "How to buy a car from a private seller",
    link: "/buy/how-to-buy-a-used-car-from-a-private-seller",
  },
  {
    article: "How to buy a car out-of-state",
    link: "/buy/buying-a-car-out-of-state",
  },
  {
    article: "How to buy a car remotely",
    link: "/payments/buying-a-car-long-distance-from-a-private-seller",
  },
  {
    article: "What is the best site to sell a car online?",
    link: "/sell/what-is-the-best-site-to-sell-a-car",
  },
  { article: "Conduct your own car auction", link: "/auction" },
  {
    article: "What is the best car-buying app?",
    link: "/buy/best-car-buying-app",
  },
  {
    article: "The safest form of payment when selling a car",
    link: "/payments/the-best-form-of-payment-when-selling-a-used-car",
  },
  {
    article: "Do I need a car escrow service?",
    link: "/escrow-service-for-used-car-transactions",
  },
];

export const SIMILAR_VIEW_LIMIT = 3;

export const buyer_data = {
  id: 4,
  __component: "page-elements.list",
  Color: "White",
  Title: "Listing fee",
  TitleStyle: "H4",
  SubTitle: "$25",
  SubTitleStyle: "H2",
  Content: "Per listing, access the best tools to sell your car privately.",
  Buttons: "Other",
  Position: "Center",
  ImagePosition: null,
  BackgroundStyle: null,
  Background: null,
  BackgroundLower: null,
  BackgroundImage: {
    data: null,
  },
  Items: [
    {
      id: 16,
      Item: "Your vehicle’s history report",
      Leader: null,
      IconPosition: "Left",
      Icon: {
        data: {
          id: 146,
          attributes: {
            name: "pricingCheck.svg",
            alternativeText: "pricingCheck.svg",
            caption: "pricingCheck.svg",
            width: 20,
            height: 20,
            formats: null,
            hash: "pricing_Check_10139da3aa",
            ext: ".svg",
            mime: "image/svg+xml",
            size: 0.27,
            url: "https://strapi-cms-media-live.s3.us-east-2.amazonaws.com/pricing_Check_10139da3aa.svg",
            previewUrl: null,
            provider: "aws-s3",
            provider_metadata: null,
            createdAt: "2023-04-13T15:52:47.060Z",
            updatedAt: "2023-04-13T15:52:56.912Z",
          },
        },
      },
    },
    {
      id: 17,
      Item: "Window brochure with QR code",
      Leader: null,
      IconPosition: "Left",
      Icon: {
        data: {
          id: 146,
          attributes: {
            name: "pricingCheck.svg",
            alternativeText: "pricingCheck.svg",
            caption: "pricingCheck.svg",
            width: 20,
            height: 20,
            formats: null,
            hash: "pricing_Check_10139da3aa",
            ext: ".svg",
            mime: "image/svg+xml",
            size: 0.27,
            url: "https://strapi-cms-media-live.s3.us-east-2.amazonaws.com/pricing_Check_10139da3aa.svg",
            previewUrl: null,
            provider: "aws-s3",
            provider_metadata: null,
            createdAt: "2023-04-13T15:52:47.060Z",
            updatedAt: "2023-04-13T15:52:56.912Z",
          },
        },
      },
    },
    {
      id: 18,
      Item: "Verified buyer funds",
      Leader: null,
      IconPosition: "Left",
      Icon: {
        data: {
          id: 146,
          attributes: {
            name: "pricingCheck.svg",
            alternativeText: "pricingCheck.svg",
            caption: "pricingCheck.svg",
            width: 20,
            height: 20,
            formats: null,
            hash: "pricing_Check_10139da3aa",
            ext: ".svg",
            mime: "image/svg+xml",
            size: 0.27,
            url: "https://strapi-cms-media-live.s3.us-east-2.amazonaws.com/pricing_Check_10139da3aa.svg",
            previewUrl: null,
            provider: "aws-s3",
            provider_metadata: null,
            createdAt: "2023-04-13T15:52:47.060Z",
            updatedAt: "2023-04-13T15:52:56.912Z",
          },
        },
      },
    },
    {
      id: 19,
      Item: "Buyer ID verification",
      Leader: null,
      IconPosition: "Left",
      Icon: {
        data: {
          id: 146,
          attributes: {
            name: "pricingCheck.svg",
            alternativeText: "pricingCheck.svg",
            caption: "pricingCheck.svg",
            width: 20,
            height: 20,
            formats: null,
            hash: "pricing_Check_10139da3aa",
            ext: ".svg",
            mime: "image/svg+xml",
            size: 0.27,
            url: "https://strapi-cms-media-live.s3.us-east-2.amazonaws.com/pricing_Check_10139da3aa.svg",
            previewUrl: null,
            provider: "aws-s3",
            provider_metadata: null,
            createdAt: "2023-04-13T15:52:47.060Z",
            updatedAt: "2023-04-13T15:52:56.912Z",
          },
        },
      },
    },
    {
      id: 20,
      Item: "Choose which payment method you’ll accept",
      Leader: null,
      IconPosition: "Left",
      Icon: {
        data: {
          id: 146,
          attributes: {
            name: "pricingCheck.svg",
            alternativeText: "pricingCheck.svg",
            caption: "pricingCheck.svg",
            width: 20,
            height: 20,
            formats: null,
            hash: "pricing_Check_10139da3aa",
            ext: ".svg",
            mime: "image/svg+xml",
            size: 0.27,
            url: "https://strapi-cms-media-live.s3.us-east-2.amazonaws.com/pricing_Check_10139da3aa.svg",
            previewUrl: null,
            provider: "aws-s3",
            provider_metadata: null,
            createdAt: "2023-04-13T15:52:47.060Z",
            updatedAt: "2023-04-13T15:52:56.912Z",
          },
        },
      },
    },
    {
      id: 21,
      Item: "Chat, test drive, and offer features",
      Leader: null,
      IconPosition: "Left",
      Icon: {
        data: {
          id: 146,
          attributes: {
            name: "pricingCheck.svg",
            alternativeText: "pricingCheck.svg",
            caption: "pricingCheck.svg",
            width: 20,
            height: 20,
            formats: null,
            hash: "pricing_Check_10139da3aa",
            ext: ".svg",
            mime: "image/svg+xml",
            size: 0.27,
            url: "https://strapi-cms-media-live.s3.us-east-2.amazonaws.com/pricing_Check_10139da3aa.svg",
            previewUrl: null,
            provider: "aws-s3",
            provider_metadata: null,
            createdAt: "2023-04-13T15:52:47.060Z",
            updatedAt: "2023-04-13T15:52:56.912Z",
          },
        },
      },
    },
  ],
  Image: {
    data: null,
  },
};

export const STATE_LIST = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
  "American Samoa",
  "District of Columbia",
  "Federated States of Micronesia",
  "Guam",
  "Marshall Islands",
  "Northern Mariana Islands",
  "Palau",
  "Puerto Rico",
  "Virgin Islands",
];

export const seller_data = {
  id: 5,
  __component: "page-elements.list",
  Color: "White",
  Title: "Closing fee",
  TitleStyle: "H4",
  SubTitle: "$150",
  SubTitleStyle: "H2",
  Content: "The safe and simple way to close the sale on your own.",
  Buttons: "Sell",
  Position: "Center",
  ImagePosition: null,
  BackgroundStyle: null,
  Background: null,
  BackgroundLower: null,
  BackgroundImage: {
    data: null,
  },
  Items: [
    {
      id: 23,
      Item: "Buyer & seller ID verification",
      Leader: null,
      IconPosition: "Left",
      Icon: {
        data: {
          id: 146,
          attributes: {
            name: "pricingCheck.svg",
            alternativeText: "pricingCheck.svg",
            caption: "pricingCheck.svg",
            width: 20,
            height: 20,
            formats: null,
            hash: "pricing_Check_10139da3aa",
            ext: ".svg",
            mime: "image/svg+xml",
            size: 0.27,
            url: "https://strapi-cms-media-live.s3.us-east-2.amazonaws.com/pricing_Check_10139da3aa.svg",
            previewUrl: null,
            provider: "aws-s3",
            provider_metadata: null,
            createdAt: "2023-04-13T15:52:47.060Z",
            updatedAt: "2023-04-13T15:52:56.912Z",
          },
        },
      },
    },
    {
      id: 24,
      Item: "Offer accept/reject/counter",
      Leader: null,
      IconPosition: "Left",
      Icon: {
        data: {
          id: 146,
          attributes: {
            name: "pricingCheck.svg",
            alternativeText: "pricingCheck.svg",
            caption: "pricingCheck.svg",
            width: 20,
            height: 20,
            formats: null,
            hash: "pricing_Check_10139da3aa",
            ext: ".svg",
            mime: "image/svg+xml",
            size: 0.27,
            url: "https://strapi-cms-media-live.s3.us-east-2.amazonaws.com/pricing_Check_10139da3aa.svg",
            previewUrl: null,
            provider: "aws-s3",
            provider_metadata: null,
            createdAt: "2023-04-13T15:52:47.060Z",
            updatedAt: "2023-04-13T15:52:56.912Z",
          },
        },
      },
    },
    {
      id: 25,
      Item: "Verified buyer funds",
      Leader: null,
      IconPosition: "Left",
      Icon: {
        data: {
          id: 146,
          attributes: {
            name: "pricingCheck.svg",
            alternativeText: "pricingCheck.svg",
            caption: "pricingCheck.svg",
            width: 20,
            height: 20,
            formats: null,
            hash: "pricing_Check_10139da3aa",
            ext: ".svg",
            mime: "image/svg+xml",
            size: 0.27,
            url: "https://strapi-cms-media-live.s3.us-east-2.amazonaws.com/pricing_Check_10139da3aa.svg",
            previewUrl: null,
            provider: "aws-s3",
            provider_metadata: null,
            createdAt: "2023-04-13T15:52:47.060Z",
            updatedAt: "2023-04-13T15:52:56.912Z",
          },
        },
      },
    },
    {
      id: 26,
      Item: "E-sign the bill of sale",
      Leader: null,
      IconPosition: "Left",
      Icon: {
        data: {
          id: 146,
          attributes: {
            name: "pricingCheck.svg",
            alternativeText: "pricingCheck.svg",
            caption: "pricingCheck.svg",
            width: 20,
            height: 20,
            formats: null,
            hash: "pricing_Check_10139da3aa",
            ext: ".svg",
            mime: "image/svg+xml",
            size: 0.27,
            url: "https://strapi-cms-media-live.s3.us-east-2.amazonaws.com/pricing_Check_10139da3aa.svg",
            previewUrl: null,
            provider: "aws-s3",
            provider_metadata: null,
            createdAt: "2023-04-13T15:52:47.060Z",
            updatedAt: "2023-04-13T15:52:56.912Z",
          },
        },
      },
    },
    {
      id: 27,
      Item: "PrivateAuto Pay",
      Leader: null,
      IconPosition: "Left",
      Icon: {
        data: {
          id: 146,
          attributes: {
            name: "pricingCheck.svg",
            alternativeText: "pricingCheck.svg",
            caption: "pricingCheck.svg",
            width: 20,
            height: 20,
            formats: null,
            hash: "pricing_Check_10139da3aa",
            ext: ".svg",
            mime: "image/svg+xml",
            size: 0.27,
            url: "https://strapi-cms-media-live.s3.us-east-2.amazonaws.com/pricing_Check_10139da3aa.svg",
            previewUrl: null,
            provider: "aws-s3",
            provider_metadata: null,
            createdAt: "2023-04-13T15:52:47.060Z",
            updatedAt: "2023-04-13T15:52:56.912Z",
          },
        },
      },
    },
    {
      id: 28,
      Item: "Instant money transfer P2P",
      Leader: null,
      IconPosition: "Left",
      Icon: {
        data: {
          id: 146,
          attributes: {
            name: "pricingCheck.svg",
            alternativeText: "pricingCheck.svg",
            caption: "pricingCheck.svg",
            width: 20,
            height: 20,
            formats: null,
            hash: "pricing_Check_10139da3aa",
            ext: ".svg",
            mime: "image/svg+xml",
            size: 0.27,
            url: "https://strapi-cms-media-live.s3.us-east-2.amazonaws.com/pricing_Check_10139da3aa.svg",
            previewUrl: null,
            provider: "aws-s3",
            provider_metadata: null,
            createdAt: "2023-04-13T15:52:47.060Z",
            updatedAt: "2023-04-13T15:52:56.912Z",
          },
        },
      },
    },
    {
      id: 29,
      Item: "Available 24/7/365",
      Leader: null,
      IconPosition: "Left",
      Icon: {
        data: {
          id: 146,
          attributes: {
            name: "pricingCheck.svg",
            alternativeText: "pricingCheck.svg",
            caption: "pricingCheck.svg",
            width: 20,
            height: 20,
            formats: null,
            hash: "pricing_Check_10139da3aa",
            ext: ".svg",
            mime: "image/svg+xml",
            size: 0.27,
            url: "https://strapi-cms-media-live.s3.us-east-2.amazonaws.com/pricing_Check_10139da3aa.svg",
            previewUrl: null,
            provider: "aws-s3",
            provider_metadata: null,
            createdAt: "2023-04-13T15:52:47.060Z",
            updatedAt: "2023-04-13T15:52:56.912Z",
          },
        },
      },
    },
    {
      id: 30,
      Item: "No commission or transaction fees",
      Leader: null,
      IconPosition: "No transaction fees",
      Icon: {
        data: {
          id: 146,
          attributes: {
            name: "pricingCheck.svg",
            alternativeText: "pricingCheck.svg",
            caption: "pricingCheck.svg",
            width: 20,
            height: 20,
            formats: null,
            hash: "pricing_Check_10139da3aa",
            ext: ".svg",
            mime: "image/svg+xml",
            size: 0.27,
            url: "https://strapi-cms-media-live.s3.us-east-2.amazonaws.com/pricing_Check_10139da3aa.svg",
            previewUrl: null,
            provider: "aws-s3",
            provider_metadata: null,
            createdAt: "2023-04-13T15:52:47.060Z",
            updatedAt: "2023-04-13T15:52:56.912Z",
          },
        },
      },
    },
  ],
  Image: {
    data: null,
  },
};

export const ROW_SIZE = 8;

export const WHITELIST_SLUGS = [
  "native/buy",
  "native/sell",
  "native/sell/vintage",
  "native/sell/details",
  "sell/vintage",
  "sell/details",
];
