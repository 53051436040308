import { create } from "zustand";

interface RegisteredRoute {
  path: string;
  position: { x: number; y: number };
}
type RouteHistoryStore = {
  lastRegisteredRoute: RegisteredRoute[];
  registerRoute: (route: string) => void;
  restoreScrollPosition(route: string): void;
};
export const useRouteHistoryStore = create<RouteHistoryStore>((set, get) => ({
  lastRegisteredRoute: [],
  registerRoute: (path: string) =>
    set((state) => {
      return {
        lastRegisteredRoute: [
          { path, position: { x: window.scrollX, y: window.scrollY } },
          ...state.lastRegisteredRoute,
        ].slice(0, 3),
      };
    }),
  restoreScrollPosition: (path: string) => {
    const { lastRegisteredRoute } = get();
    const savedRoute = lastRegisteredRoute
      .slice(1)
      .find((route) => route.path === path);
    if (savedRoute) {
      setTimeout(() => {
        window.scrollTo(savedRoute.position.x, savedRoute.position.y);
      }, 200);
    }
  },
}));
