import { useAuthValue } from "@/contexts/contextAuth";
import { setScrollHidden } from "@/libs/utils";
import {
  hasRunningRequestsSelector,
  useApiRequestTracker,
} from "@/stores/api-request-tracker.store";
import AppModal from "./AppModal";
import { useEffect, useMemo } from "react";
import clsx from "classnames";
import { AuthenticationType } from "../authentication/types";
import { Authentication } from "../authentication/Authentication";
import { AppModalType, useAppModalStore } from "@/stores/app-modal.store";
import DealNowExplainedModal from "../buy/modals/DealNowExplained";
import { loginAppUrl } from "@/libs/clientConfig";
import { createSelectors } from "@/stores/create-selectors";

const LoginModal = () => {
  const {
    isSignin,
    isSignup,
    setSource,
    setIsSignin,
    setIsSignup,
    isResetPassword,
    source,
  } = useAuthValue();
  const isLoading = useApiRequestTracker(hasRunningRequestsSelector);

  const appModalStore = createSelectors(useAppModalStore);
  const appModalType = useAppModalStore((state) => state.type);
  const closeModal = appModalStore.use.closeModal();
  const isDealNowExplainedModal = Boolean(
    appModalType === AppModalType.DealNowExplainer
  );
  const isModalOpen =
    isSignin || isSignup || isDealNowExplainedModal || isResetPassword;

  function closeAuthModal(open: boolean) {
    if (!open && isDealNowExplainedModal) {
      closeModal();
      return;
    }
    if (isLoading && isModalOpen) {
      return;
    }
    if (!open) {
      setIsSignin(false);
      setIsSignup(false);
      setScrollHidden();
      document.body.style.overflowY = "scroll";
    }
  }

  const authScreen = useMemo(() => {
    if (isSignin) {
      return AuthenticationType.Signin;
    }
    if (isSignup) {
      return AuthenticationType.Signup;
    }
    if (isResetPassword) {
      return AuthenticationType.ResetPassword;
    }
    return null;
  }, [isSignin, isSignup, isResetPassword]);

  useEffect(() => {
    if (source) {
      window?.fbq && window.fbq("track", source);
      window?.dataLayer &&
        window.dataLayer.push({
          event: source,
          Login: "pre",
        });
    }

    const accountType =
      source === "Message Seller" ||
      source === "Schedule Test Drive" ||
      source === "Make Offer" ||
      source === "SERVICES"
        ? "BUYER"
        : "TBD";

    if (isSignup) {
      let AccountType = source === "SELLER" ? "SELLER" : accountType;
      window?.dataLayer &&
        window.dataLayer.push({
          event: "Create Account",
          AccountType,
          Login: "pre",
        });
    }
    setSource(`LOGIN_APP_URL: ${loginAppUrl()}`);
  }, [isSignin, isSignup]);

  return (
    <AppModal onToggle={closeAuthModal} open={isModalOpen}>
      {authScreen ? (
        <div className="w-full flex justify-center py-8">
          <div className={clsx("w-4/5")}>
            <Authentication initialScreen={authScreen} />
          </div>
        </div>
      ) : appModalType === AppModalType.DealNowExplainer ? (
        <DealNowExplainedModal />
      ) : null}
    </AppModal>
  );
};

export default LoginModal;
