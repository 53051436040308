import { useAuthValue } from "@/contexts/contextAuth";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";

export function useSocialSignin() {
  const { onSocialLogin } = useAuthValue();

  function initiateSocialSignin(provider: CognitoHostedUIIdentityProvider) {
    onSocialLogin(mapProviderToLabel(provider));
  }

  function mapProviderToLabel(provider: CognitoHostedUIIdentityProvider) {
    // const mapping = {
    //   [CognitoHostedUIIdentityProvider.Apple]: "Apple",
    //   [CognitoHostedUIIdentityProvider.Google]: "Google",
    //   [CognitoHostedUIIdentityProvider.Facebook]: "Facebook",
    //   [CognitoHostedUIIdentityProvider.Amazon]: "Amazon",
    //   [CognitoHostedUIIdentityProvider.Cognito]: "Cognito",
    // };
    return provider;
  }

  return {
    initiateSocialSignin,
    mapProviderToLabel,
  };
}
