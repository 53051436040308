import "../styles/globals.css";
import "react-tooltip/dist/react-tooltip.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "@fortawesome/fontawesome-free/js/all.js";
import type { AppProps } from "next/app";
import { AppProviders } from "@/components/AppProviders";
import Layout from "@/components/layout/Layout";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useState } from "react";
import { Amplify } from "aws-amplify";

import { amplifyConfig } from "@/libs/clientConfig";
import TrackingScripts from "@/components/scripts/TrackingScripts";
import { useRouteTracker } from "@/hooks/useRouteTracker";

Amplify.configure(amplifyConfig);

export default function App({ Component, pageProps }: AppProps) {
  const [queryClient] = useState(() => new QueryClient());
  useRouteTracker();
  return (
    <QueryClientProvider client={queryClient}>
      <TrackingScripts />
      <AppProviders clientConfig={pageProps.clientConfig}>
        <Layout>
          <Component {...pageProps} />
        </Layout>
      </AppProviders>
    </QueryClientProvider>
  );
}
