import {
  SignupSchema,
  useSignupForm as useResetPasswordForm,
} from "@/hooks/useSignupForm";
import { useState } from "react";
import StrongPassword from "./StrongPassword";
import Image from "next/image";
import { AuthenticationScreenProps, AuthenticationType } from "./types";
import { useApiRequestTracker } from "@/stores/api-request-tracker.store";
import { useRouter } from "next/router";
import getApi from "@/hooks/useApi";
import { useAuthValue } from "@/contexts/contextAuth";

const api = getApi();
export const ResetPassword = ({ changeScreen }: AuthenticationScreenProps) => {
  const apiRequestTrackerStore = useApiRequestTracker();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting: isLoading, isValid },
    watch,
  } = useResetPasswordForm();
  const [showPassword, setShowPassword] = useState<Boolean>(false);
  const passwordValue = watch("password");
  const router = useRouter();
  const { setMessage, setType, setIsShow } = useAuthValue();

  const resetPassword = async (data: SignupSchema) => {
    apiRequestTrackerStore.increment();
    try {
      const { email, password } = data;
      await api.resetPassword({
        email: email.toLowerCase(),
        password,
        token: router.query.token as string,
      });
      setType("success");
      setMessage("The password has been successfully reset.");
      setIsShow(true);
      changeScreen(AuthenticationType.Signin);
    } catch (err: any) {
      setType("error");
      setMessage(err.message);
      setIsShow(true);
    }
    apiRequestTrackerStore.decrement();
  };
  return (
    <form onSubmit={handleSubmit(resetPassword)}>
      <div className="text-[1.75rem] text-[#212529] font-bold">
        Change your password
      </div>
      <div className="text-base font-medium pt-2">
        <p>Create a new password for your account.</p>
      </div>
      <div className="py-2">
        <input
          type="email"
          placeholder="Email address"
          className="border-[#9797aa] focus:border-[#00a0c7] form-control w-full block px-4 py-2 text-base font-medium bg-white bg-clip-padding border border-solid  rounded m-0"
          {...register("email")}
        />
        {errors.email && (
          <div className="text-xs text-left text-[#ed0a0a] pt-2">
            {errors.email.message}
          </div>
        )}
      </div>
      <div className="relative py-2">
        <input
          type={`${showPassword ? "text" : "password"}`}
          className="bg-no-repeat bg-[center_right_0.5rem] border-[#9797aa] focus:border-[#00a0c7] form-control w-full block px-4 py-2 text-base font-medium bg-white bg-clip-padding border border-solid  rounded m-0"
          placeholder="New Password"
          {...register("password")}
        />
        {errors.password && (
          <div className="text-xs text-left text-[#ed0a0a] pt-2">
            {errors.password.message}
          </div>
        )}
        <StrongPassword password={passwordValue} />
        {showPassword ? (
          <Image
            className="absolute top-5 right-2"
            width={17}
            height={15}
            src={`/assets/eyeCrossedOut.svg`}
            alt="eye"
            onClick={() => setShowPassword(!showPassword)}
          />
        ) : (
          <Image
            className="absolute top-5 right-2"
            width={15}
            height={11}
            src={`/assets/eye.svg`}
            alt="eye"
            onClick={() => setShowPassword(!showPassword)}
          />
        )}
      </div>
      <div className="py-6">
        <button
          className={`w-full bg-[#17a2b8] text-white text-base font-bold py-2 px-4 border-[#117a8b] rounded ${
            isValid ? "opacity-100" : "opacity-50"
          }`}
          type="submit"
          disabled={!isValid}
        >
          Save password
        </button>
      </div>
    </form>
  );
};
