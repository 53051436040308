import { useAuthValue } from "@/contexts/contextAuth";
import { useAppModalStore } from "@/stores/app-modal.store";
import { createSelectors } from "@/stores/create-selectors";

const DealNowExplainedModal = () => {
  const { setIsSignup, setRedirectUrl } = useAuthValue();
  const appModalStore = createSelectors(useAppModalStore);
  const metadata = appModalStore.use.metadata?.()!;
  const closeModal = appModalStore.use.closeModal();

  return (
    <div className="w-full h-auto flex py-5 flex-col justify-center items-center mb-5 px-10">
      <div className="max-w-[442px] mx-4 sm:mx-0">
        <div className="text-[34px] font-bold max-w-[420px]">
          How DealNow works for off-site listings:
        </div>

        <div className="flex items-center mt-4 text-[16px] font-semibold">
          <img
            src="/assets/fireIcon.svg"
            alt="fireIcon"
            className="mr-3 w-[30px]"
          />
          <div className="max-w-[265px]">
            Use DealNow to send an offer to a seller anywhere.
          </div>
        </div>
        <div className="flex items-center mt-4 text-[16px] font-semibold">
          <img
            src="/assets/externalLinkIcon.svg"
            alt="fireIcon"
            className="mr-3 w-[30px]"
          />
          <div className="max-w-[265px]">
            Message the seller the DealNow invite on the external listing site
          </div>
        </div>
        <div className="flex items-center mt-4 text-[16px] font-semibold">
          <img
            src="/assets/smallPALogo.svg"
            alt="fireIcon"
            className="mr-3 w-[30px]"
          />
          <div className="max-w-[265px]">
            Close a deal no matter where the car is listed or located.
          </div>
        </div>
        <div className="flex items-center mt-4 text-[16px] font-semibold">
          <img
            src="/assets/dollar-circle-green.svg"
            alt="fireIcon"
            className="mr-3 w-[30px]"
          />
          <div className="max-w-[265px]">
            Instant transfer of money P2P with no transaction fees
          </div>
        </div>
      </div>
      <button
        className="btn-primary tex-center w-full  mt-10 max-w-[300px]"
        onClick={() => {
          setRedirectUrl(
            `/listing/${metadata.slug}?externalListingDealNow=true`
          );
          setIsSignup(true, {
            redirectUrl: `/listing/${metadata.slug}?externalListingDealNow=true`,
          });
          closeModal();
        }}
      >
        Get started
      </button>
    </div>
  );
};

export default DealNowExplainedModal;
