import Image from "next/image";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import { Dialog, DialogContent, DialogHeader } from "@/components/ui/dialog";
import { Drawer, DrawerContent, DrawerHeader } from "@/components/ui/drawer";
import { ReactNode } from "react";

interface Props {
  children: ReactNode;
  open: boolean;
  onToggle(open: boolean): void;
}

const AppModal = (props: Props) => {
  const isDesktop = useMediaQuery("(min-width: 768px)");
  const { children, open, onToggle } = props;
  if (!children) {
    return null;
  }
  if (isDesktop) {
    return (
      <Dialog open={open} onOpenChange={onToggle}>
        <DialogContent className="sm:max-w-lg">
          <DialogHeader>
            <Image
              width={134}
              height={24}
              src={`/assets/logo.svg`}
              alt="logo"
            />
          </DialogHeader>
          {children}
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Drawer open={open} onOpenChange={onToggle}>
      <DrawerContent>
        <DrawerHeader>
          <Image width={134} height={24} src={`/assets/logo.svg`} alt="logo" />
        </DrawerHeader>
        {children}
      </DrawerContent>
    </Drawer>
  );
};

export default AppModal;
