import { useEffect, useMemo, useRef } from "react";
import { useRouter } from "next/router";

type StateToQueryFunction<T> = (state: T) => string;
type QueryToState<T> = (query: string) => T;

export function useUrlState<T>(
  stateToQuery: StateToQueryFunction<T>,
  querytoState: QueryToState<T>
) {
  const router = useRouter();
  const [basePath, parsedQuery] = router.asPath.split(/\?/);
  const isMounted = useRef(false);
  useEffect(() => {
    isMounted.current = true;
  }, []);
  const updateQuery = (state: T) => {
    if (isMounted.current) {
      const newQuery = stateToQuery(state);
      const newPath = `${basePath}?${newQuery}`;
      router.replace(newPath, undefined, { shallow: true });
    }
  };

  const syncedState = useMemo(() => {
    return querytoState(parsedQuery);
  }, [parsedQuery]);

  return {
    syncedState,
    updateQuery,
  };
}
