/**********************************************************************
 *   This is for CLIENT variables only!
 *   Server side should be accessed directly
 *
 *********************************************************************/

import { SellerListing } from "@/interfaces/listing/SellerListing";

if (!process.env.NEXT_PUBLIC_BASE_URL)
  throw new Error("No host name found - NEXT_PUBLIC_BASE_URL is not set!");

const baseUrl = new URL(process.env.NEXT_PUBLIC_BASE_URL);

const imageBase = new URL(
  process.env.NEXT_PUBLIC_API_IMG_URL ?? process.env.NEXT_PUBLIC_BASE_URL
);

const hostName = baseUrl.hostname;

const protocol = baseUrl.protocol;
const port = baseUrl.port;

export const publicDomain = () => hostName;

export function isLive() {
  return publicDomain() === "privateauto.com";
}

const publicBase = () => baseUrl;

const makeUrl = (base?: string, path = "/"): string => {
  let urlStr = "";
  let baseHost = base;
  try {
    if (!base && !publicBase()) return path ?? "/";
    baseHost = new URL(base ?? "", publicBase()).host.replace(/prelogin\./, "");
    urlStr = `${protocol}${protocol.endsWith(":") ? "" : ":"}//${baseHost}`;
    if (port && port != "80" && port != "443" && !urlStr.endsWith(`:${port}`)) {
      urlStr += `:${port}`;
    }
    const url = new URL(path ?? "/", urlStr).toString();
    return url;
  } catch (e: any) {
    console.log(
      `makeUrl(): ${
        baseHost ?? base
      } publicDomain: ${publicDomain()} protocol: ${protocol} port: ${port} path: ${path}  [${urlStr}] => ${
        e.message
      }`,
      e
    );
    return "/";
  }
};

export interface ClientConfig {
  hostName: string;
  publicDomain: string;
  isLive: boolean;
  apiUrl: string;
}

export const hashSecret =
  process.env.NEXT_PUBLIC_HASH_SECRET_KEY ?? "whQ5V2C2SEyzhtWYM4j6dA";
export const secretToken =
  process.env.NEXT_PUBLIC_SECRET_TOKEN ?? "privateauto";
export const googleAutocompleteApiKey =
  process.env.NEXT_PUBLIC_GOOGLE_AUTOCOMPLETE_KEY ??
  "AIzaSyABX4LTqTLQGg_b3jFOH8Z6_H5CDqn8tbc";
export const googleMapsApiKey =
  process.env.NEXT_PUBLIC_GOOGLE_MAPS_KEY ??
  "AIzaSyDCoYgKMvU9_kpGutWJEcr74vLFUMv5N88";
export const googleGeocodeApiKey =
  process.env.NEXT_PUBLIC_GOOGLE_GEOCODE_KEY ??
  "AIzaSyDCoYgKMvU9_kpGutWJEcr74vLFUMv5N88";
export const googleRecaptchaApiKey =
  process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_KEY ??
  "AIzaSyDCoYgKMvU9_kpGutWJEcr74vLFUMv5N88";

// These are consistent between environments
export const alogoliaAppId = process.env.NEXT_PUBLIC_ALGOLIA_APPID ?? "";
export const alogoliaPublicKey = process.env.NEXT_PUBLIC_ALGOLIA_API_KEY ?? "";

// This are different between Prod and Dev, but no extremely sensitive
export const runBuggyClientId = () =>
  (isLive()
    ? process.env.NEXT_PUBLIC_PROD_RUNBUGGY_ID
    : process.env.NEXT_PUBLIC_DEV_RUNBUGGY_ID) ?? "";

export const runBuggyScriptUrl = () =>
  (isLive()
    ? process.env.NEXT_PUBLIC_PROD_RUNBUGGY_URL
    : process.env.NEXT_PUBLIC_DEV_RUNBUGGY_URL) ?? "";

export const airbrakeConfig = () => ({
  env: isLive() ? "live" : "dev",
  projectId: Number(process.env.NEXT_PUBLIC_AIRBRAKE_PROJECT!),
  projectKey: process.env.NEXT_PUBLIC_AIRBRAKE_KEY!,
});

export const buildId = process.env.PA_BUILD_ID ?? "n/a";

function apiUrl() {
  return makeUrl(process.env.PA_LISTING_API);
}

export function loginAppUrl() {
  if (publicDomain() === "") return "/external-auth";
  return makeUrl(`https://auth.${publicDomain()}`, "/external-auth");
}

export function createClientConfig(): ClientConfig {
  const cfg = {
    hostName: hostName,
    publicDomain: publicDomain(),
    isLive: isLive(),
    apiUrl: apiUrl(),
  };

  return cfg;
}

export function makeAbsoluteUrl(url: string, def?: string) {
  try {
    if (url) return makeUrl(publicDomain(), url);
  } catch (e: any) {
    console.log(`makeAbsoluteUrl(${url})[${publicDomain()}] - ${e.message}`);
  }
  return def;
}

export function makeApiImage(
  url: string,
  def: string = "/assets/imagePlaceholder.svg"
): string {
  try {
    if (url && url.trim().length > 0) {
      const parts = url.split("/").filter((p) => p.trim().length > 0);
      if (parts[0] == "images") parts.shift();
      if (parts[0] != "static") parts.unshift("static");
      let path = parts.join("/");
      return new URL(path, imageBase).toString();
    }
  } catch (e: any) {
    console.log(`makeApiImage([${url}]) failed - ${e.message}`);
  }
  return def;
}

export function makeListingImage(url: string, vehicleType: string): string {
  try {
    if (url && url.trim().length > 0) {
      const parts = url.split("/").filter((p) => p.trim().length > 0);
      if (parts[0] == "images") parts.shift();
      if (parts[0] != "static") parts.unshift("static");
      let path = parts.join("/");
      return new URL(path, imageBase).toString();
    }
  } catch (e: any) {
    console.log(`makeApiImage([${url}]) failed - ${e.message}`);
  }

  return `/assets/listings/new-listing-${
    vehicleType === "Boat" ? "boat" : vehicleType === "RV" ? "rv" : "car"
  }.svg`;
}

export const getDistributionId = () =>
  isLive()
    ? process.env.AWS_PROD_CLOUDFRONT_DISTRIBUTION_ID
    : process.env.AWS_DEV_CLOUDFRONT_DISTRIBUTION_ID;

const AWS_REGION = process.env.NEXT_PUBLIC_AWS_REGION ?? "us-east-1";
export const amplifyConfig = {
  aws_project_region: AWS_REGION,
  aws_cognito_identity_pool_id: process.env.NEXT_PUBLIC_COGNITO_IDENTITY_POOL,
  aws_cognito_region: AWS_REGION,
  aws_user_pools_id: process.env.NEXT_PUBLIC_COGNITO_USER_POOL,
  aws_user_pools_web_client_id: process.env.NEXT_PUBLIC_COGNITO_CLIENT_ID,
  Auth: {
    identityPoolId: process.env.NEXT_PUBLIC_COGNITO_IDENTITY_POOL,
    region: AWS_REGION,
    identityPoolRegion: AWS_REGION,
    userPoolId: process.env.NEXT_PUBLIC_COGNITO_USER_POOL,
    userPoolWebClientId: process.env.NEXT_PUBLIC_COGNITO_CLIENT_ID,
  },
};
