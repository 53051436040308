import { SignInSchema, useSignInForm } from "@/hooks/useSignInForm";
import Image from "next/image";
import { useCallback, useState } from "react";
import { SocialSignin } from "./SocialSignin";
import { AuthenticationScreenProps, AuthenticationType } from "./types";
import { delay } from "@/libs/utils";
import { useAuthValue } from "@/contexts/contextAuth";
import { useApiRequestTracker } from "@/stores/api-request-tracker.store";

export const Signin = ({ changeScreen }: AuthenticationScreenProps) => {
  const apiRequestTrackerStore = useApiRequestTracker();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting: isLoading },
  } = useSignInForm();
  const [showPassword, setShowPassword] = useState<Boolean>(false);

  const { onLogin, setMessage, setType, setIsShow } = useAuthValue();

  const onSubmit = useCallback(
    handleSubmit(async (data: SignInSchema) => {
      apiRequestTrackerStore.increment();
      try {
        const successUrl = await onLogin(data.email, data.password);
        setType("success");
        setMessage("Login Successful");
        setIsShow(true);
        await delay(500);
        window.location.href = successUrl;
      } catch {}
      apiRequestTrackerStore.decrement();
    }),
    []
  );
  return (
    <form onSubmit={onSubmit} className="w-full">
      <div className="text-[2rem] text-[#212529] font-bold">Sign in</div>
      <div className="text-base text-[#212529] font-medium py-2">
        New to Here?&nbsp;
        <span
          className="text-[#00b3de] underline cursor-pointer"
          role="link"
          onClick={() => changeScreen(AuthenticationType.Signup)}
        >
          Create an account
        </span>
      </div>
      <div className="py-2">
        <input
          type="email"
          className="border-[#9797aa] focus:border-[#00a0c7] form-control w-full block px-4 py-2 text-base font-medium bg-white bg-clip-padding border border-solid  rounded m-0"
          placeholder="Email address"
          {...register("email")}
        />
        {errors?.email && (
          <div className="text-xs text-left text-[#ed0a0a] pt-2">
            {errors.email.message}
          </div>
        )}
      </div>
      <div className="relative py-2">
        <input
          type={`${showPassword ? "text" : "password"}`}
          className="bg-no-repeat bg-[center_right_0.5rem] border-[#9797aa] focus:border-[#00a0c7] form-control w-full block px-4 py-2 text-base font-medium bg-white bg-clip-padding border border-solid  rounded m-0"
          placeholder="Password"
          {...register("password")}
        />
        {errors?.password && (
          <div className="text-xs text-left text-[#ed0a0a] pt-2">
            {errors?.password?.message}
          </div>
        )}
        {showPassword ? (
          <Image
            className="absolute top-5 right-2"
            width={17}
            height={15}
            src={`/assets/eyeCrossedOut.svg`}
            alt="eye"
            onClick={() => setShowPassword(!showPassword)}
          />
        ) : (
          <Image
            className="absolute top-6 right-2"
            width={15}
            height={11}
            src={`/assets/eye.svg`}
            alt="eye"
            onClick={() => setShowPassword(!showPassword)}
          />
        )}
      </div>
      <div className="py-2">
        <button
          className="w-full bg-[#00b3de] hover:opacity-80 text-[#fff] text-base font-bold py-3 px-4 border border-[#00a0c7] rounded cursor-pointer"
          type="submit"
        >
          Login
        </button>
      </div>
      <div className="py-2">
        <span
          className="text-[#00b3de] font-medium cursor-pointer"
          role="link"
          onClick={() => changeScreen(AuthenticationType.ForgotPassword)}
        >
          Forgot Password?
        </span>
      </div>
      <hr className="text-[#c4c4c4] my-2" />
      <SocialSignin />
    </form>
  );
};
