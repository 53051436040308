import { passwordRequirements } from "@/hooks/useSignupForm";
import Image from "next/image";
import { useMemo } from "react";

type Props = {
  password: string;
};

const StrongPassword = ({ password }: Props) => {
  const allPassed = useMemo(
    () => passwordRequirements.every((e) => e.regex.test(password)),
    [password]
  );
  return (
    <div className="w-full">
      {allPassed ? (
        <div className="w-full bg-[#dff3e7] flex items-center space-x-2 px-4 mt-8 rounded">
          <Image
            width={16}
            height={16}
            src={`/assets/strong-password.svg`}
            alt="valid"
          />
          <p className="text-base text-[#212529] font-medium py-2">
            Your password is strong!
          </p>
        </div>
      ) : (
        password != "" && (
          <div className="w-full space-y-2 pt-4">
            {passwordRequirements.map((requirement, i) => (
              <div key={i} className="flex space-x-1 text-sm">
                {requirement.regex.test(password) ? (
                  <Image
                    width={16}
                    height={16}
                    src={`/assets/valid.svg`}
                    alt="valid"
                  />
                ) : (
                  <Image
                    width={16}
                    height={16}
                    src={`/assets/not-valid.svg`}
                    alt="not-valid"
                  />
                )}
                <p
                  className={`${
                    requirement.regex.test(password)
                      ? "text-[#808080]"
                      : "text-[#212529]"
                  }`}
                >
                  {requirement.description}
                </p>
              </div>
            ))}
          </div>
        )
      )}
    </div>
  );
};

export default StrongPassword;
