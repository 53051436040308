import { create } from "zustand";

export interface DealNowExplainer {
  slug: string;
}

type AppModalMetadata = DealNowExplainer; // use type OR for custom metadata
export enum AppModalType {
  DealNowExplainer = "deal-now-explainer",
}

type AppModalStore = {
  closeModal(): void;
  openModal(metadata: AppModalMetadata, type: AppModalType): void;
  metadata?: AppModalMetadata;
  type?: AppModalType;
};

export const useAppModalStore = create<AppModalStore>((set) => ({
  isVisible: false,
  closeModal: () => set({ metadata: undefined, type: undefined }),
  openModal: (metadata, type: AppModalType) => set({ metadata, type }),
}));
