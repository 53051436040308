import { createContext, useContext, ReactNode } from "react";
import PropTypes from "prop-types";

import useAuth from "@/hooks/useAuth";

declare global {
  interface Window {
    dataLayer: any[];
    fbq: (...args: any[]) => void;
    rdt: (...args: any[]) => void;
    uipe: (...args: any[]) => void;
  }
}

export const AuthContext = createContext({
  isSignin: false,
  isCreateListing: false,
  isListingAction: "",
  isDealNowDeal: "",
  isDealNowDealStart: "",
  coBrandedName: "",
  setCoBrandedName: (value: string) => {},
  setIsSignin: (value: boolean, redirectParams?: Record<string, unknown>) => {},
  isSignup: false,
  setIsSignup: (value: boolean, redirectParams?: Record<string, unknown>) => {},
  source: "",
  setSource: (value: string) => {},
  type: "",
  setType: (value: string) => {},
  message: "",
  setMessage: (value: string) => {},
  isShow: false,
  setIsShow: (value: boolean) => {},
  isLoading: false,
  setIsLoading: (value: boolean) => {},
  // handleMessage: (e: any) => {},
  setIsCreateListing: (value: boolean) => {},
  setIsListingAction: (value: string) => {},
  setIsDealNowDeal: (value: string) => {},
  setIsDealNowDealStart: (value: string) => {},
  isFavMark: "",
  setIsFavMark: (value: string) => {},
  isVehicleHistory: "",
  setIsVehicleHistory: (value: string) => {},
  isLoanAction: "",
  setIsLoanAction: (value: string) => {},
  redirectUrl: "",
  setRedirectUrl: (value: string) => {},
  isResetPassword: false,
  setIsResetPassword: (value: boolean) => {},
  isAccountBannerVisible: false,
  onLogin: async (email: string, password: string) => "",
  onSocialLogin: (provider: string) => {},
});

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const {
    isSignin,
    isSignup,
    type,
    message,
    isShow,
    isLoading,
    isListingAction,
    isCreateListing,
    isDealNowDeal,
    isDealNowDealStart,
    isFavMark,
    isVehicleHistory,
    isLoanAction,
    source,
    coBrandedName,
    setIsSignin,
    setCoBrandedName,
    setIsSignup,
    setType,
    setMessage,
    setIsShow,
    setIsLoading,
    setSource,
    setIsCreateListing,
    setIsListingAction,
    setIsDealNowDeal,
    setIsDealNowDealStart,
    setIsFavMark,
    setIsVehicleHistory,
    setIsLoanAction,
    redirectUrl,
    setRedirectUrl,
    setIsResetPassword,
    isResetPassword,
    isAccountBannerVisible,
    onLogin,
    onSocialLogin,
  } = useAuth();

  return (
    <AuthContext.Provider
      value={{
        isSignin,
        setIsSignin,
        isSignup,
        setIsSignup,
        type,
        setType,
        message,
        setMessage,
        isShow,
        setIsShow,
        isLoading,
        setIsLoading,
        source,
        setSource,
        // handleMessage,
        setIsCreateListing,
        isListingAction,
        setIsListingAction,
        isDealNowDeal,
        setIsDealNowDeal,
        coBrandedName,
        setCoBrandedName,
        isDealNowDealStart,
        setIsDealNowDealStart,
        isFavMark,
        setIsFavMark,
        isVehicleHistory,
        setIsVehicleHistory,
        isLoanAction,
        setIsLoanAction,
        isCreateListing,
        redirectUrl,
        setRedirectUrl,
        isResetPassword,
        setIsResetPassword,
        isAccountBannerVisible,
        onLogin,
        onSocialLogin,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthValue = () => useContext(AuthContext);

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
