import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";

export const passwordRequirements = [
  {
    description: "One upper and lower letter (Ab)",
    regex: /(?=.*[a-z])(?=.*[A-Z])/,
  },
  {
    description: "One numeric number (123)",
    regex: /[0-9]/,
  },
  {
    description: "One special character (!@#...)",
    regex: /[\s!"#$'()*+,-.:;<=>?@[\]^_`{|}~]/,
  },
  {
    description: "8 characters minimum in length",
    regex: /^.{8,64}$/,
  },
];

const registerFormSchema = z.object({
  email: z
    .string({
      required_error: "Please select an email to display.",
    })
    .min(1, "Email is required")
    .email("Invalid email"),
  password: z.string().superRefine((val, ctx) => {
    if (val.trim() === "") {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Password is required",
      });
      return;
    }
    const isPasswordValid = passwordRequirements.every((requirement) =>
      requirement.regex.test(val)
    );
    if (!isPasswordValid) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Password does not meet the requirements",
      });
    }
  }),
});

export type SignupSchema = z.infer<typeof registerFormSchema>;
export const useSignupForm = () => {
  return useForm<SignupSchema>({
    resolver: zodResolver(registerFormSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });
};
