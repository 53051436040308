import { createContext, useContext, ReactNode } from "react";
import PropTypes from "prop-types";
import { MoreFilter } from "@/interfaces/MoreFilter";
import { initFilters } from "@/libs/constants";
import {
  MIN_PRICE,
  MAX_PRICE,
  MIN_YEAR,
  MAX_YEAR,
  MIN_MILES,
  MAX_MILES,
} from "@/libs/constants";
import qs from "qs";

import useSearch from "@/hooks/useSearch";
import { useUrlState } from "@/hooks/useUrlState";

export type MoreFiltersInterface = ReturnType<
  typeof useSearch
>["moreFiltersArr"];

type SearchParametersInterface = Pick<
  ReturnType<typeof useSearch>,
  | "bodyType"
  | "current"
  | "lat"
  | "lng"
  | "location"
  | "make"
  | "models"
  | "radius"
  | "maxMiles"
  | "maxPrice"
  | "maxYear"
  | "minMiles"
  | "minPrice"
  | "minYear"
  | "searchKey"
  | "vehicleType"
  | "state"
  | "sort"
  | "isVerified"
> &
  MoreFiltersInterface;

const stateToQuery = (state: SearchParametersInterface) => qs.stringify(state);

const queryToState = (query: string): SearchParametersInterface => {
  const result = qs.parse(query) as any;
  const numberKeys = [
    "current",
    "limit",
    "lat",
    "lng",
    "maxMiles",
    "maxPrice",
    "maxYear",
    "minMiles",
    "minPrice",
    "minYear",
    "radius",
    "rows",
  ];
  return Object.fromEntries(
    Object.entries(result).map(([key, value]) => [
      key,
      numberKeys.includes(key) ? Number(value) : value,
    ])
  ) as SearchParametersInterface;
};

export const SearchContext = createContext({
  searchKey: "",
  setSearchKey: (value: string) => {},
  vehicleType: "",
  setVehicleType: (value: string) => {},
  make: "",
  setMake: (value: string) => {},
  models: Array<string>(),
  setModels: (value: Array<string>) => {},
  bodyType: Array<string>(),
  setBodyType: (value: Array<string>) => {},
  minPrice: MIN_PRICE,
  setMinPrice: (value: number) => {},
  maxPrice: MAX_PRICE,
  setMaxPrice: (value: number) => {},
  minYear: MIN_YEAR,
  setMinYear: (value: number) => {},
  maxYear: MAX_YEAR,
  setMaxYear: (value: number) => {},
  minMiles: MIN_MILES,
  setMinMiles: (value: number) => {},
  maxMiles: MAX_MILES,
  setMaxMiles: (value: number) => {},
  isVerified: "",
  setIsVerified: (value: string) => {},
  moreFiltersArr: initFilters,
  setMoreFiltersArr: (value: MoreFilter) => {},
  state: "",
  setState: (value: string) => {},
  location: "",
  setLocation: (value: string) => {},
  zipCode: "",
  setZipCode: (value: string) => {},
  lat: 0,
  setLat: (value: number) => {},
  lng: 0,
  setLng: (value: number) => {},
  radius: 50,
  setRadius: (value: number) => {},
  sort: "Newest",
  setSort: (value: string) => {},
  selectedRadius: 50,
  setSelectedRadius: (value: number) => {},
  selectedPlace: "",
  setSelectedPlace: (value: string) => {},
  selectedState: "",
  setSelectedState: (value: string) => {},
  selectedLat: 0,
  setSelectedLat: (value: number) => {},
  selectedLng: 0,
  setSelectedLng: (value: number) => {},
  isOnlyState: false,
  setIsOnlyState: (value: boolean) => {},
  current: 0,
  setCurrent: (value: number) => {},
  clearLocation: () => {},
  clearMakeModel: () => {},
  clearFilters: () => {},
  syncedState: {} as SearchParametersInterface,
  updateQuery: (state: SearchParametersInterface) => {},
  triggerReset: false,
  setTriggerReset: (value: boolean) => {},
});

export const SearchProvider = ({ children }: { children: ReactNode }) => {
  const {
    vehicleType,
    setVehicleType,
    searchKey,
    setSearchKey,
    make,
    setMake,
    models,
    setModels,
    bodyType,
    setBodyType,
    minPrice,
    setMinPrice,
    maxPrice,
    setMaxPrice,
    minYear,
    setMinYear,
    maxYear,
    setMaxYear,
    minMiles,
    setMinMiles,
    maxMiles,
    setMaxMiles,
    isVerified,
    setIsVerified,
    moreFiltersArr,
    setMoreFiltersArr,
    state,
    setState,
    location,
    setLocation,
    zipCode,
    setZipCode,
    lat,
    setLat,
    lng,
    setLng,
    radius,
    setRadius,
    sort,
    setSort,
    selectedRadius,
    setSelectedRadius,
    selectedPlace,
    setSelectedPlace,
    selectedState,
    setSelectedState,
    selectedLat,
    setSelectedLat,
    selectedLng,
    setSelectedLng,
    isOnlyState,
    setIsOnlyState,
    current,
    setCurrent,
    clearLocation,
    clearMakeModel,
    clearFilters,
    triggerReset,
    setTriggerReset,
  } = useSearch();

  const { syncedState, updateQuery } = useUrlState(stateToQuery, queryToState);

  return (
    <SearchContext.Provider
      value={{
        vehicleType,
        setVehicleType,
        searchKey,
        setSearchKey,
        make,
        setMake,
        models,
        setModels,
        bodyType,
        setBodyType,
        minPrice,
        setMinPrice,
        maxPrice,
        setMaxPrice,
        minYear,
        setMinYear,
        maxYear,
        setMaxYear,
        minMiles,
        setMinMiles,
        maxMiles,
        setMaxMiles,
        isVerified,
        setIsVerified,
        moreFiltersArr,
        setMoreFiltersArr,
        state,
        setState,
        location,
        setLocation,
        zipCode,
        setZipCode,
        lat,
        setLat,
        lng,
        setLng,
        radius,
        setRadius,
        sort,
        setSort,
        selectedRadius,
        setSelectedRadius,
        selectedPlace,
        setSelectedPlace,
        selectedState,
        setSelectedState,
        selectedLat,
        setSelectedLat,
        selectedLng,
        setSelectedLng,
        isOnlyState,
        setIsOnlyState,
        current,
        setCurrent,
        clearLocation,
        clearMakeModel,
        clearFilters,
        syncedState,
        updateQuery,
        triggerReset,
        setTriggerReset,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

export const useSearchValue = () => useContext(SearchContext);

SearchProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
